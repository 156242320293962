import validator from "validator";

export const baseValidation = [
    (v: string) => !validator.isEmpty(v) || "This value is required",
    (v: string) => v.length < 50 || "This value must be less than 50 characters in length",
    (v: string) => validator.isAscii(v) || "Only Ascii characters are excepted",
];

export const isStrongPasswordStrength = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");

export const isMediumPasswordStrength = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");

//At least 6 chars, has small letters, cap letters and digits
export const isStandardPasswordStrength = new RegExp(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/);

//At least 6 chars, one letter and one digit
export const isLowPasswordStrength = new RegExp("^(?=.*[A-Za-z])(?=.*d)[A-Za-zd]{6,}$");

export const isPostCode = new RegExp(/\b[A-Z]{1,2}[0-9][A-Z0-9]?\s?[0-9][ABD-HJLNP-UW-Z]{2}\b/g, "i");
