/* eslint-disable @typescript-eslint/no-non-null-assertion,no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
//Based on https://github.com/wsmd/react-use-form-state

import { generateID, isNullOrEmpty } from "../../Utils/Utils";
import { IObservableArray, isObservableArray, observable, runInAction } from "mobx";
import { ObjPathProxy, getPath } from "ts-object-path";
import { useEffect, useRef } from "react";

import { IModel } from "../../Models";
import { IViewModel } from "../../ViewModels";
import { Moment } from "moment";
import React from "react";
import axios from "axios";
import { PostCodeLookUpModel } from "../../Models/PostCodeLookUpModel";
import { KeyValuePair } from "../../Models";
import { InputAdornment } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import { FieldType } from "../../Models";

export const CHECKBOX = "checkbox";
export const COLOR = "color";
export const DATE = "date";
export const EMAIL = "email";
export const MONTH = "month";
export const NUMBER = "number";
export const FILE = "file";
export const PASSWORD = "password";
export const RADIO = "radio";
export const RANGE = "range";
export const SEARCH = "search";
export const SELECT = "select";
export const TEL = "tel";
export const TEXT = "text";
export const TIME = "time";
export const URL = "url";
export const WEEK = "week";
export const MULTISELECT = "multiselect";
export const POSTCODE = "postcode";
/**
 * @todo add support for datetime-local
 */
export const DATETIME_LOCAL = "datetime-local";
/**
 * @todo add support for a multiple select
 */
export const MULTIPLE = "multiple";

export const TYPES = [CHECKBOX, COLOR, DATE, EMAIL, MONTH, NUMBER, PASSWORD, RADIO, RANGE, SEARCH, SELECT, TEL, TEXT, TIME, URL, WEEK, MULTISELECT, FILE, POSTCODE];

type Maybe<T> = T;

type TModel<T> = IModel<T> & any;

interface InputOptions {
    id?: string;
    validateOnBlur?: boolean;
    selectItems?: KeyValuePair[];
}

interface Inputs<T extends TModel<T>> {
    select(fieldName: keyof FieldType<T>, inputOptions?: InputOptions): Omit<InputProps, "type">;
    email(fieldName: keyof FieldType<T>, inputOptions?: InputOptions): InputProps;
    postcode(fieldName: keyof FieldType<T>, inputOptions?: InputOptions): InputProps;
    file(fieldName: keyof FieldType<T>, inputOptions?: InputOptions): Omit<InputProps, "value">;
    color(fieldName: keyof FieldType<T>, inputOptions?: InputOptions): InputProps;
    password(fieldName: keyof FieldType<T>, inputOptions?: InputOptions): InputProps;
    text(fieldName: keyof FieldType<T> | string, inputOptions?: InputOptions): InputProps;
    number(fieldName: keyof FieldType<T>, inputOptions?: InputOptions): InputProps;
    url(fieldName: keyof FieldType<T>, inputOptions?: InputOptions): InputProps;
    search(fieldName: keyof FieldType<T>, inputOptions?: InputOptions): InputProps;
    range(fieldName: keyof FieldType<T>, inputOptions?: InputOptions): InputProps;
    tel(fieldName: keyof FieldType<T>, inputOptions?: InputOptions): InputProps;
    radio(fieldName: keyof FieldType<T>, inputOptions: InputOptions | null): Omit<InputProps & CheckedProp, "type">;
    checkbox(fieldName: keyof FieldType<T>, inputOptions?: InputOptions): Omit<InputProps & CheckedProp, "type">;
    date(fieldName: keyof FieldType<T>, inputOptions?: InputOptions): InputProps;
    month(fieldName: keyof FieldType<T>, inputOptions?: InputOptions): InputProps;
    week(fieldName: keyof FieldType<T>, inputOptions?: InputOptions): InputProps;
    time(fieldName: keyof FieldType<T>, inputOptions?: InputOptions): InputProps;
    multiselect(fieldName: keyof FieldType<T>, inputOptions?: InputOptions): InputProps;
}

interface CheckedProp {
    checked: boolean;
}

interface InputProps {
    onChange(e: any): void;
    onBlur(e: any): void;
    onFocus(e: any): void;
    checked: boolean;
    value: string | number | string[] | undefined;
    id: string;
    type?: string;
    error: boolean;
}

export function Validation<T extends TModel<T>>(viewModel: IViewModel<T>, onChangeCallback?: any): [ObjPathProxy<T, T>, Inputs<T>] {
    // const formHandler = useState(defaultValues);
    //const [mounted, setMounted] = useState(false);
    const uniqueId = useRef(generateID());

    // initial mounted flag
    useEffect(() => {}, []);

    const validate = async (fieldName: keyof FieldType<T>, value: any) => {
        // if (props.required) {
        // 	if (props.type === "text") {
        // 		let isValid = value !== "";
        // 		props.viewModel.setValid(props.fieldName, isValid);
        // 		props.viewModel.setError(props.fieldName, `${props.label} is required.`);
        // 		return;
        // 	}
        // }
        await viewModel.isFieldValid(fieldName, value);
    };

    const createPropsGetter =
        (type: any) =>
        (fieldName: keyof FieldType<T>, inputOptions: InputOptions | null, ownValue: string = "") => {
            if (!inputOptions) {
                inputOptions = {};
            }
            //EN: If we are passing in a proxy convert it back into an object string path
            if (typeof fieldName !== "string") {
                const p = getPath(fieldName as any as string);
                // @ts-ignore
                fieldName = p.join(".") as string;
            }

            const lookUpPostCode = async (value: string) => {
                if (value.length > 0) {
                    let model = (viewModel as any)["getModel"];
                    let postcode = value.replace(" ", "").toUpperCase();
                    let response: any = await axios.get(
                        "https://shoothillfunctions.azurewebsites.net/api/PostCodeLookup?code=1eIpBWAklBECpT1iYgkR9cdCdHp7vKGbLVwQtZ1t6FoSfLBjooYuuQ==&postcode=" + postcode,
                    );
                    if (response && response.data && response.data.postCode !== "") {
                        await runInAction(() => {
                            let lookup = response.data as PostCodeLookUpModel;
                            model["location"] = lookup;
                            model["lat"] = lookup.lat;
                            model["lon"] = lookup.lon;
                        });
                        if (response.data.postCode !== null) {
                            viewModel.setValue(fieldName, response.data.postCode);
                        } else {
                            await runInAction(() => {
                                model["location"] = undefined;
                                model["lat"] = 0;
                                model["lon"] = 0;
                            });
                            viewModel.setValue(fieldName, value);
                            viewModel.setError(fieldName, "Postcode is not valid");
                        }
                    }
                }
            };

            const fileInputChange = (input: any) => {
                if (input.target.files && input.target.files[0]) {
                    let reader = new FileReader();

                    reader.onload = function (e) {
                        //$('#blah').attr('src', e.target.result);
                        viewModel.setValue(fieldName, e!.target!.result);
                    };

                    reader.readAsDataURL(input.target.files[0]);
                }
            };

            const getTargetValue = (e: any): string | boolean | Date | Moment | number | null | any[] => {
                let value: string | number | boolean | Date | Moment | null;
                if (type === NUMBER) {
                    value = e.target.value;
                    if (isNaN(parseFloat(value as any))) {
                        return null;
                    }
                    return parseFloat(value as any);
                } else if (type === DATE) {
                    return e;
                } else if (type === CHECKBOX) {
                    return e.target.checked;
                } else {
                    return e.target.value;
                }
            };

            const inputProps = {
                get name() {
                    return fieldName;
                },
                get id() {
                    if (type !== SELECT && type !== RADIO) {
                        if (!inputOptions!.id || inputOptions!.id === "") {
                            return fieldName.toString() + "-" + uniqueId.current;
                        }
                        return inputOptions!.id;
                    }
                    return inputOptions!.id;
                },
                get error() {
                    if (!viewModel.getValid(fieldName)) {
                        return true;
                    }
                    return undefined;
                },
                get type() {
                    if (type === DATE || type === TIME) {
                        return "text";
                    }
                    if (type !== SELECT && type !== RADIO) {
                        return type;
                    }
                    if (type === RADIO) {
                        return "radio";
                    }
                    return undefined;
                },
                get checked() {
                    return viewModel.getValue(fieldName);
                },
                get value() {
                    let value = viewModel.getValue<string>(fieldName);
                    if (type === MULTISELECT && isObservableArray(value)) {
                        return (value as IObservableArray).toJS();
                    } else if (type === SELECT) {
                        /*let selectItems = inputOptions!.selectItems as KeyValuePair[];
                    let selected = selectItems.filter((a) => a.value == value);
                    if (selected && selected.length > 0) {
                        return selected[0].key;
                    }*/
                        return value;
                    }
                    if (type === FILE) {
                        return ""; //EN: Return empty string here because input file cannot be SET to any value other than an empty string
                    }

                    return value;
                },
                get multiple() {
                    return type === MULTISELECT ? true : undefined;
                },
                onClick(e: any) {
                    const { value: targetValue, checked } = e.target;
                    if (type === RADIO) {
                    }
                },
                async onChange(e: React.ChangeEvent<HTMLInputElement>) {
                    const value = getTargetValue(e);
                    const currentValue = viewModel.getValue(fieldName);
                    if (type === MULTISELECT && isObservableArray(currentValue)) {
                        viewModel.setValue(fieldName, observable(value! as any[]));
                    } else if (type === TEL) {
                        let regexp = /^$|^[0-9,+]+$/;
                        if (regexp.test(value! as string)) {
                            viewModel.setValue(fieldName, value!);
                        }
                    } else if (type == NUMBER) {
                        viewModel.setValue(fieldName, parseFloat(value! as string));
                    } else if (type == DATE) {
                        let momentDate = value as Moment;
                        try {
                            if (momentDate && momentDate.isValid && momentDate.isValid()) {
                                viewModel.setValue(fieldName, (momentDate! as Moment).toISOString(true));
                            }
                        } catch {}
                    } else if (type == POSTCODE) {
                        if (isNullOrEmpty(value! as string)) {
                            let model = (viewModel as any)["getModel"];
                            model["location"] = undefined;
                            model["lat"] = 0;
                            model["lon"] = 0;
                        }
                        viewModel.setValue(fieldName, value!);
                    } else if (type == FILE) {
                        fileInputChange(e);
                    } else {
                        viewModel.setValue(fieldName, value!);
                    }

                    viewModel.setDirty(fieldName, true);

                    if (!inputOptions!.validateOnBlur) {
                        validate(fieldName, value);
                    }

                    if (onChangeCallback) {
                        onChangeCallback();
                    }
                },
                onFocus(e: React.ChangeEvent<HTMLInputElement>) {
                    viewModel.setTouched(fieldName, true);
                },
                async onBlur(e: React.ChangeEvent<HTMLInputElement>) {
                    const value = getTargetValue(e);

                    if (type == POSTCODE) {
                        await lookUpPostCode(value as string);
                    }

                    if (inputOptions!.validateOnBlur) {
                        validate(fieldName, value);
                    }
                },
            };
            return inputProps;
        };

    const typeMethods = TYPES.reduce(
        (methods: any, type: string) => ({
            ...methods,
            [type]: createPropsGetter(type),
        }),
        {},
    );

    return [viewModel.getContext(), typeMethods];
}
