import * as MobX from "mobx";
import { ModelBase } from "./ModelBase";
import { IsNotEmpty } from "class-validator";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class BlankModel extends ModelBase<BlankModel, BlankModelDTO> {
    @MobX.observable
    @IsNotEmpty({ message: "You must give me your name!!" })
    public firstName: string = "";

    //fromDto is required but you can leave it blank
    fromDto(model: BlankModelDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                let k = (this as any)[key];
                if (k instanceof Date) {
                    k = new Date((model as any)[key]);
                } else {
                    k = (model as any)[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: BlankModel): void {}
}

export type BlankModelDTO = {
    firstName: string;
};
