/* eslint-disable @typescript-eslint/no-parameter-properties */
import { action, observable, runInAction } from "mobx";
import * as GoogleAnalytics from "../Utils/App/GoogleAnalyticsUtils";
import { FormLockConcurrencyHubStore } from "./FormLockConcurrencyHubStore";
import * as MobX from "mobx";
import { useTheme } from "@material-ui/core/styles";
import { EventArgs } from "../Utils";
import React, { createContext } from "react";
import { Theme } from "@material-ui/core";
import * as History from "history";

export class CoreStore {
    public GlobalHistory = {} as History.History;
    @observable public IsLoggedIn = false;
    @observable public DisplayName = "";
    @observable public screenWidth = 0;
    @observable public isMobile = false;
    @observable public isTablet = false;
    @observable public isDesktop = false;
    public FormLockConcurrencyHubStore = new FormLockConcurrencyHubStore();
    private theme = {} as Theme;

    public constructor() {
        CoreStoreInstance = this;
    }

    public get Theme() {
        return this.theme;
    }

    public SetHistory = (history: History.History) => {
        this.GlobalHistory = history;
    };

    public SetTheme = (theme: Theme) => {
        this.theme = theme;
    };

    @action
    public SetLoggedIn(isLoggedIn: boolean) {
        this.IsLoggedIn = isLoggedIn;
    }

    @action
    public SetDisplayName(displayName: string) {
        this.DisplayName = displayName;
    }

    public Init = (signalRAccessToken: string, useFormLockConcurrency: boolean) => {
        //console.assert(this.theme.palette, "You must set the theme before initiating. Use SetTheme");
        //return;

        window.addEventListener("resize", () => {
            this.setWidth();
        });
        this.setWidth();
        this.FormLockConcurrencyHubStore.init(signalRAccessToken, useFormLockConcurrency);

        // EN: For debugging
        //window.MobX = MobX;
    };

    private setWidth = () => {
        runInAction(() => {
            this.screenWidth = document.body.clientWidth;
            this.isMobile = this.screenWidth <= this.theme.breakpoints.values["md"];
            this.isTablet = this.screenWidth > this.theme.breakpoints.values["md"] && this.screenWidth <= this.theme.breakpoints.values["lg"];
            this.isDesktop = this.screenWidth > this.theme.breakpoints.values["lg"];
        });
    };

    public PageView = (path: string, title: string = "") => {
        GoogleAnalytics.pageview(path, "");
    };

    public SendEvent = (args: EventArgs) => {
        GoogleAnalytics.sendEvent(args);
    };
}

export let CoreStoreInstance = {} as CoreStore;
export const CoreStoreContext: React.Context<CoreStore> = createContext(CoreStoreInstance);
