import React from "react";
import { Grid } from "./Grid/Grid";

interface Props {
    children: any;
}

export const Centered = ({ children }: Props) => {
    return (
        <Grid dc={"1fr"}>
            <div>{children}</div>
        </Grid>
    );
};
