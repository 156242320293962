import { getJWT, setJWT } from "../Utils";
import { AccountStatus, ApiResult, ApiResultError, ApiResultErrorType, ModelBase } from "../Models";
import { CheckHttpStatus, MergeDefaultConfig } from "../Utils";
import { getHistory, getParentObjectPath } from "../Utils";
import { FieldType } from "../Models";
import { History, LocationState } from "history";
import { MetadataStorage, Validator, validate, getMetadataStorage, ValidationError } from "class-validator";
import * as ValidationTypes from "class-validator";
import { createProxy, ObjPathProxy } from "ts-object-path";
import { action, computed, observable, runInAction } from "mobx";
import axios, * as Axios from "axios";
import { IModel } from "../Models";
import { IViewModel } from "./IViewModel";
import { ValidationMetadata } from "class-validator/types/metadata/ValidationMetadata";
import { get as _get, isBoolean as _isBoolean, isString as _isString } from "lodash";
import { createViewModel } from "mobx-utils";
import dot from "dot-object";
import { match } from "react-router";
import { CoreStoreInstance } from "../Stores";

export type ValidationResponse = {
    isValid: boolean;
    errorMessage: string;
};
export type Create<T> = new (...args: any[]) => T;
export abstract class ViewModelBase<T extends IModel<T> = any> implements IViewModel<T> {
    public model: T = {} as T;
    private modelReference: any = null;

    @observable public IsLoading: boolean = false;
    @observable public IsErrored = false;
    @observable public Errors: string = "";
    @observable public Valid: boolean = false;

    @action protected setIsLoading = (state: boolean) => (this.IsLoading = state);
    @action protected setIsErrored = (state: boolean) => (this.IsErrored = state);
    @action protected setErrors = (state: string) => (this.Errors = state);

    public history: History;
    public location: LocationState = {} as LocationState;
    public match = {} as match<{}>;

    //public validatorStorage: MetadataStorage = getFromContainer(MetadataStorage);
    public validatorStorage: MetadataStorage = getMetadataStorage();
    public meta = {} as ValidationMetadata[];
    private validator = new Validator();
    private proxy: T = {} as T;

    protected constructor(model?: T, undoable?: boolean, enableProxy?: boolean) {
        this.history = getHistory();

        if (!undoable) undoable = false;
        if (!enableProxy) enableProxy = true;

        if (model) {
            if (enableProxy) {
                this.createNewProxy(model, undoable);
            } else {
                this.internalSetModel(model, undoable);
            }
        }
        (window as any).model = model;
    }

    public createNewProxy = (model: T, undoable: boolean) => {
        let self: IViewModel<T> = this;
        this.proxy = new Proxy(model, {
            get(target: any, value: any, receiver: any) {
                let val = Reflect.get(target, value, receiver); // (1)
                return typeof value == "function" ? val.bind(target) : val;
            },
            set(target: any, prop: any, value: any, receiver: any) {
                let newValue = value;
                if (typeof (self as any)["beforeUpdate"] === "function") {
                    let tmpValue = (self as any)["beforeUpdate"](prop, value);
                    if (tmpValue !== null && tmpValue !== undefined) {
                        newValue = tmpValue;
                    }
                }
                let retval = Reflect.set(target, prop, newValue, receiver); // (1)
                if (typeof (self as any)["afterUpdate"] === "function") {
                    (self as any)["afterUpdate"](prop, newValue);
                }
                return retval;
            },
        });
        this.internalSetModel(this.proxy, undoable);
    };

    private getType = <T>(TCtor: new (...args: any[]) => T) => {
        return typeof TCtor;
    };

    //This must be overriden in any class that extends this base class
    abstract isFieldValid(fieldName: keyof FieldType<T>, value: any): Promise<boolean>;
    abstract beforeUpdate?(fieldName: keyof FieldType<T>, value: any): any;
    abstract afterUpdate?(fieldName: keyof FieldType<T>, value: any): void;

    @computed
    public get getModel(): T {
        return this.model;
    }
    @computed
    public get screenWidth(): number {
        return CoreStoreInstance.screenWidth;
    }
    @computed
    public get isMobile(): boolean {
        return CoreStoreInstance.isMobile;
    }
    @computed
    public get isTablet(): boolean {
        return CoreStoreInstance.isTablet;
    }
    @computed
    public get isDesktop(): boolean {
        return CoreStoreInstance.isDesktop;
    }
    /*  @computed
  public get isLoggedIn(): boolean {
    return CoreStoreInstance.domain.AccountStore.IsLoggedIn;
  }*/
    private internalSetModel(model: T, undoable: boolean = false) {
        if (undoable) {
            //This is a helper method to make the model undoable. You must call submit on the model to save changes
            this.model = createViewModel(model);
            return;
        }
        this.model = model;
    }

    public setModel(model: T, reset: boolean = true, undoable: boolean = false) {
        if (reset) {
            this.model = model;
        }
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this.getValue(key as any) instanceof Date) {
                    this.setValue(key as any, new Date(model[key] as any));
                } else {
                    this.setValue(key as any, model[key]);
                }
            }
        }
    }

    public getContext = (): ObjPathProxy<T, T> => {
        return createProxy<T>();
    };

    public saveModel(): void {
        (this.model as any).submit();
    }

    public resetModel(): void {
        (this.model as any).reset();
    }

    @action
    public setValue<TR>(fieldName: keyof FieldType<T>, value: TR) {
        if (!this.model.setValue) {
            console.error("setValue does not exist on Model. Are you sure you have created an intsance of the model. IE. new MyModel()");
        }
        this.model.setValue<TR>(fieldName, value);
    }

    public getValue<TR>(fieldName: keyof FieldType<T>): TR {
        if (!this.model.getValue) {
            console.error("getValue does not exist on Model. Are you sure you have created an intsance of the model. IE. new MyModel()");
        }
        let value = this.model.getValue<TR>(fieldName);
        if (value === null) {
            if (_isString(value)) {
                (value as any as string) = "";
            } else if (_isBoolean(value)) {
                (value as any as boolean) = false;
            }
            this.model.setValue(fieldName, value);
        }
        return value;
    }

    @action
    public setError(fieldName: keyof FieldType<T>, value: string) {
        this.model.setError(fieldName, value);
    }

    public getError(fieldName: keyof FieldType<T>) {
        return this.model.getError(fieldName);
    }

    @action
    public setValid(fieldName: keyof FieldType<T>, value: boolean): void {
        this.model.setValid(fieldName, value);
    }

    public getValid(fieldName: keyof FieldType<T>): boolean {
        return this.model.getValid(fieldName);
    }

    @action
    public setDirty(fieldName: keyof FieldType<T>, value: boolean): void {
        this.model.setDirty(fieldName, value);
    }

    public getDirty(fieldName: keyof FieldType<T>): boolean {
        return this.model.getDirty(fieldName);
    }

    @action
    public setTouched(fieldName: keyof FieldType<T>, value: boolean): void {
        this.model.setTouched(fieldName, value);
    }

    public getTouched(fieldName: keyof FieldType<T>): boolean {
        return this.model.getTouched(fieldName);
    }

    public isModelValid = async (): Promise<boolean> => {
        let valid = true;
        //EN: Flatten the object into dot notation so we can iterate over nested objects
        let target = dot.dot(this.model);
        for (let prop in target) {
            if (prop.indexOf("Errors.") < 0 && prop.indexOf("Dirty.") < 0 && prop.indexOf("Touched.") < 0 && prop.indexOf("Valid.") < 0) {
                if (prop != "getParentObjectPath") {
                    //this["isFieldValid"](prop as any, _get(this.model, prop));
                    await this.isFieldValid(prop as any, _get(this.model, prop));
                }
            }
        }

        // //Run through again checking properties of model
        for (let prop in target) {
            if (prop.indexOf("Errors.") < 0 && prop.indexOf("Dirty.") < 0 && prop.indexOf("Touched.") < 0 && prop.indexOf("Valid.") < 0) {
                if (valid) {
                    let path = getParentObjectPath(prop, "Valid");
                    valid = _get(this.model, path);
                }
            }
        }
        runInAction(() => {
            this.Valid = valid;
        });
        return valid;
    };

    private parseObjectProperties = (obj: any, parse: any) => {
        for (let k in obj) {
            if (typeof obj[k] === "object" && obj[k] !== null) {
                this.parseObjectProperties(obj[k], parse);
            } else if (obj.hasOwnProperty(k)) {
                parse(obj, k);
            }
        }
    };

    public setDecorators = (model: any) => {
        this.modelReference = model;
        this.meta = this.validatorStorage.getTargetValidationMetadatas(model, "", false, false);
        let c = this.validatorStorage.getTargetValidatorConstraints(this.model.constructor);
    };

    public getOwnPropertyDescriptors(obj: any) {
        const result = {} as any;
        for (let key of Reflect.ownKeys(obj)) {
            result[key] = Object.getOwnPropertyDescriptor(obj, key);
        }
        return result;
    }

    public validateModel = async (): Promise<ValidationError[]> => {
        let validated = true;
        let message = "";

        return await validate(this.model);
    };

    public validateDecorators = async (fieldName: keyof FieldType<T>): Promise<ValidationResponse> => {
        let validated = true;
        let message = "";

        //EN: Not a good way of doing this. It validates all fields each time
        let errors = await validate(this.model);

        if (errors && errors.length > 0) {
            errors.forEach((e: any) => {
                if (e.property === fieldName.toString()) {
                    validated = false;
                    message = e.constraints[Object.keys(e.constraints)[0]];
                }
            });
        }

        return {
            isValid: validated,
            errorMessage: validated ? "" : message.toString(),
        };

        /*let thisFieldName: any = fieldName as string;
        if ((fieldName as string).split(".").length > 1) {
            let split = (fieldName as string).split(".");
            thisFieldName = split[split.length - 1];
        }
        let target = this.meta.filter((a) => a.propertyName === thisFieldName).reverse();
        let message = "";
        if (target && target.length > 0) {
            let validated: any = false;
            target.some((t: ValidationMetadata) => {
                validated = this.validateValueByMetadata(this.getValue(fieldName), t!);
                if (t.message) {
                    message = t.message.toString();
                } else if (t.constraints.length > 0) {
                    message = t.constraints[0].message;
                }
                return !validated;
            });
            return {
                isValid: validated,
                errorMessage: validated ? "" : message.toString(),
            };
        } else {
            //No decorators found so presume no validation required
            return { isValid: true, errorMessage: "" };
        }*/
    };

    public validateValueByMetadata(value: any, metadata: ValidationMetadata): boolean {
        switch (metadata.type) {
            /* common checkers */
            case ValidationTypes.IS_DEFINED:
                return ValidationTypes.isDefined(value);
            case ValidationTypes.EQUALS:
                return ValidationTypes.equals(value, metadata.constraints[0]);
            case ValidationTypes.NOT_EQUALS:
                return ValidationTypes.notEquals(value, metadata.constraints[0]);
            case ValidationTypes.IS_EMPTY:
                return ValidationTypes.isEmpty(value);
            case ValidationTypes.IS_NOT_EMPTY:
                return ValidationTypes.isNotEmpty(value);
            case ValidationTypes.IS_IN:
                return ValidationTypes.isIn(value, metadata.constraints[0]);
            case ValidationTypes.IS_NOT_IN:
                return ValidationTypes.isNotIn(value, metadata.constraints[0]);

            /* type checkers */
            case ValidationTypes.IS_LATLONG:
                return ValidationTypes.isLatLong(value);
            case ValidationTypes.IS_LATITUDE:
                return ValidationTypes.isLatitude(value);
            case ValidationTypes.IS_LONGITUDE:
                return ValidationTypes.isLongitude(value);
            case ValidationTypes.IS_BOOLEAN:
                return ValidationTypes.isBoolean(value);
            case ValidationTypes.IS_DATE:
                return ValidationTypes.isDate(value);
            case ValidationTypes.IS_STRING:
                return ValidationTypes.isString(value);
            case ValidationTypes.IS_DATE_STRING:
                return ValidationTypes.isDateString(value);
            case ValidationTypes.IS_ARRAY:
                return ValidationTypes.isArray(value);
            case ValidationTypes.IS_NUMBER:
                return ValidationTypes.isNumber(value, metadata.constraints[0]);
            case ValidationTypes.IS_INT:
                return ValidationTypes.isInt(value);
            case ValidationTypes.IS_ENUM:
                return ValidationTypes.isEnum(value, metadata.constraints[0]);

            /* number checkers */
            case ValidationTypes.IS_DIVISIBLE_BY:
                return ValidationTypes.isDivisibleBy(value, metadata.constraints[0]);
            case ValidationTypes.IS_POSITIVE:
                return ValidationTypes.isPositive(value);
            case ValidationTypes.IS_NEGATIVE:
                return ValidationTypes.isNegative(value);
            case ValidationTypes.MIN:
                return ValidationTypes.min(value, metadata.constraints[0]);
            case ValidationTypes.MAX:
                return ValidationTypes.max(value, metadata.constraints[0]);

            /* date checkers */
            case ValidationTypes.MIN_DATE:
                return ValidationTypes.minDate(value, metadata.constraints[0]);
            case ValidationTypes.MAX_DATE:
                return ValidationTypes.maxDate(value, metadata.constraints[0]);

            /* string-as-type checkers */
            case ValidationTypes.IS_BOOLEAN_STRING:
                return ValidationTypes.isBooleanString(value);
            case ValidationTypes.IS_NUMBER_STRING:
                return ValidationTypes.isNumberString(value);

            /* string checkers */
            case ValidationTypes.CONTAINS:
                return ValidationTypes.contains(value, metadata.constraints[0]);
            case ValidationTypes.NOT_CONTAINS:
                return ValidationTypes.notContains(value, metadata.constraints[0]);
            case ValidationTypes.IS_ALPHA:
                return ValidationTypes.isAlpha(value, metadata.constraints[0]);
            case ValidationTypes.IS_ALPHANUMERIC:
                return ValidationTypes.isAlphanumeric(value, metadata.constraints[0]);
            case ValidationTypes.IS_DECIMAL:
                return ValidationTypes.isDecimal(value, metadata.constraints[0]);
            case ValidationTypes.IS_ASCII:
                return ValidationTypes.isAscii(value);
            case ValidationTypes.IS_BASE64:
                return ValidationTypes.isBase64(value);
            case ValidationTypes.IS_BYTE_LENGTH:
                return ValidationTypes.isByteLength(value, metadata.constraints[0], metadata.constraints[1]);
            case ValidationTypes.IS_CREDIT_CARD:
                return ValidationTypes.isCreditCard(value);
            case ValidationTypes.IS_CURRENCY:
                return ValidationTypes.isCurrency(value, metadata.constraints[0]);
            case ValidationTypes.IS_EMAIL:
                return ValidationTypes.isEmail(value, metadata.constraints[0]);
            case ValidationTypes.IS_FQDN:
                return ValidationTypes.isFQDN(value, metadata.constraints[0]);
            case ValidationTypes.IS_FULL_WIDTH:
                return ValidationTypes.isFullWidth(value);
            case ValidationTypes.IS_HALF_WIDTH:
                return ValidationTypes.isHalfWidth(value);
            case ValidationTypes.IS_VARIABLE_WIDTH:
                return ValidationTypes.isVariableWidth(value);
            case ValidationTypes.IS_HEX_COLOR:
                return ValidationTypes.isHexColor(value);
            case ValidationTypes.IS_HEXADECIMAL:
                return ValidationTypes.isHexadecimal(value);
            case ValidationTypes.IS_MAC_ADDRESS:
                return ValidationTypes.isMACAddress(value);
            case ValidationTypes.IS_IP:
                return ValidationTypes.isIP(value, metadata.constraints[0]);
            case ValidationTypes.IS_PORT:
                return ValidationTypes.isPort(value);
            case ValidationTypes.IS_ISBN:
                return ValidationTypes.isISBN(value, metadata.constraints[0]);
            case ValidationTypes.IS_ISIN:
                return ValidationTypes.isISIN(value);
            case ValidationTypes.IS_ISO8601:
                return ValidationTypes.isISO8601(value, metadata.constraints[0]);
            case ValidationTypes.IS_JSON:
                return ValidationTypes.isJSON(value);
            case ValidationTypes.IS_JWT:
                return ValidationTypes.isJWT(value);
            case ValidationTypes.IS_OBJECT:
                return ValidationTypes.isObject(value);
            case ValidationTypes.IS_NOT_EMPTY_OBJECT:
                return ValidationTypes.isNotEmptyObject(value);
            case ValidationTypes.IS_LOWERCASE:
                return ValidationTypes.isLowercase(value);
            case ValidationTypes.IS_MOBILE_PHONE:
                return ValidationTypes.isMobilePhone(value, metadata.constraints[0]);
            case ValidationTypes.IS_PHONE_NUMBER:
                return ValidationTypes.isPhoneNumber(value, metadata.constraints[0]);
            case ValidationTypes.IS_ISO31661_ALPHA_2:
                return ValidationTypes.isISO31661Alpha2(value);
            case ValidationTypes.IS_ISO31661_ALPHA_3:
                return ValidationTypes.isISO31661Alpha3(value);
            case ValidationTypes.IS_MONGO_ID:
                return ValidationTypes.isMongoId(value);
            case ValidationTypes.IS_MULTIBYTE:
                return ValidationTypes.isMultibyte(value);
            case ValidationTypes.IS_SURROGATE_PAIR:
                return ValidationTypes.isSurrogatePair(value);
            case ValidationTypes.IS_URL:
                return ValidationTypes.isURL(value, metadata.constraints[0]);
            case ValidationTypes.IS_UUID:
                return ValidationTypes.isUUID(value, metadata.constraints[0]);
            //case ValidationTypes.IS_FIREBASE_PUSH_ID:
            //    return ValidationTypes.IsFirebasePushId(value);
            case ValidationTypes.IS_UPPERCASE:
                return ValidationTypes.isUppercase(value);
            case ValidationTypes.IS_LENGTH:
                return ValidationTypes.length(value, metadata.constraints[0], metadata.constraints[1]);
            case ValidationTypes.MIN_LENGTH:
                return ValidationTypes.minLength(value, metadata.constraints[0]);
            case ValidationTypes.MAX_LENGTH:
                return ValidationTypes.maxLength(value, metadata.constraints[0]);
            case ValidationTypes.MATCHES:
                return ValidationTypes.matches(value, metadata.constraints[0], metadata.constraints[1]);
            case ValidationTypes.IS_MILITARY_TIME:
                return ValidationTypes.isMilitaryTime(value);
            case ValidationTypes.IS_HASH:
                return ValidationTypes.isHash(value, metadata.constraints[0]);
            case ValidationTypes.IS_ISSN:
                return ValidationTypes.isISSN(value, metadata.constraints[0]);

            /* array checkers */
            case ValidationTypes.ARRAY_CONTAINS:
                return ValidationTypes.arrayContains(value, metadata.constraints[0]);
            case ValidationTypes.ARRAY_NOT_CONTAINS:
                return ValidationTypes.arrayNotContains(value, metadata.constraints[0]);
            case ValidationTypes.ARRAY_NOT_EMPTY:
                return ValidationTypes.arrayNotEmpty(value);
            case ValidationTypes.ARRAY_MIN_SIZE:
                return ValidationTypes.arrayMinSize(value, metadata.constraints[0]);
            case ValidationTypes.ARRAY_MAX_SIZE:
                return ValidationTypes.arrayMaxSize(value, metadata.constraints[0]);
            case ValidationTypes.ARRAY_UNIQUE:
                return ValidationTypes.arrayUnique(value);

            case ValidationTypes.IS_INSTANCE:
                return ValidationTypes.isInstance(value, metadata.constraints[0]);
        }
        return true;
    }

    public getModelAsPayload(): T {
        let payload = this.getAnyModelAsPayload(this.model);
        return payload;
    }

    private getAnyModelAsPayload(model: any): T {
        let exclude = ["Dirty", "Errors", "Valid", "Touched", "localComputedValues", "localValues", "isPropertyDirty"];
        let payload = {} as T;
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (!exclude.includes(key)) {
                    //EN: Check for recursed models
                    if (key == "model" && typeof model[key] === "object") {
                        continue;
                    }
                    (payload as any)[key] = (this.model as any)[key];
                    if (typeof (payload as any)[key] === "string") {
                        //EN: Exclude null characters in a string
                        ((payload as any)[key] as any as string).replace(/\0/g, "");
                    }
                }
            }
        }
        return payload;
    }

    Get = async <TPayload = ApiResult<undefined>>(
        url: string,
        //model?: any,
        useBearer: boolean = true,
        config?: Axios.AxiosRequestConfig,
    ): Promise<ApiResult<TPayload>> => {
        this.setIsLoading(true);
        let thisconfig = await this.getConfig(useBearer, config);

        const postPromise = axios
            .get<ApiResult<TPayload>>(url, thisconfig)
            .then(async (response) => {
                if (response.headers["token-expired"]) {
                    let jwt = await getJWT();
                    let newTokenResult = await axios.post<ApiResult<any>>("/api/account/refresh", {
                        accessToken: jwt,
                    });
                    await this.setLoginState(newTokenResult.data.payload);
                    if (newTokenResult.data.payload.jwt === "") {
                        //Go to session expired page
                        window.location.href = "/sessionexpired";
                        //return false;
                    }
                    //Make the original call again
                    response = await axios.get<ApiResult<TPayload>>(url, thisconfig);
                } else if (response.headers["unauthorized"]) {
                    this.logout();
                }
                CheckHttpStatus(response);
                this.setIsLoading(false);
                return response.data;
            })
            .catch((error) => {
                return this.processError(error);
            });

        return postPromise as Promise<ApiResult<TPayload>>;
    };

    Post = async <TPayload = ApiResult<undefined>>(url: string, model?: any, useBearer: boolean = true, config?: Axios.AxiosRequestConfig): Promise<ApiResult<TPayload>> => {
        this.setIsLoading(true);
        let payload = model;
        if (model instanceof ModelBase) {
            payload = this.getAnyModelAsPayload(model);
        }
        let thisconfig = await this.getConfig(useBearer, config);
        const postPromise = axios
            .post<ApiResult<TPayload>>(url, payload, thisconfig)
            .then(async (response) => {
                if (response.headers["token-expired"]) {
                    let jwt = await getJWT();
                    let newTokenResult = await axios.post<ApiResult<any>>("/api/account/refresh", {
                        accessToken: jwt,
                    });
                    await this.setLoginState(newTokenResult.data.payload);
                    if (newTokenResult.data.payload.jwt === "") {
                        //Go to session expired page
                        window.location.href = "/sessionexpired";
                    }
                    response = await axios.post<ApiResult<TPayload>>(url, model, thisconfig);
                } else if (response.headers["unauthorized"]) {
                    this.logout();
                }
                CheckHttpStatus(response);
                this.setIsLoading(false);

                return response.data;
            })
            .catch((error) => {
                return this.processError(error);
            });

        return postPromise as Promise<ApiResult<TPayload>>;
    };

    processError = (error: any) => {
        this.setIsErrored(true);
        this.setIsLoading(false);
        this.setErrors(error);
        let errors: ApiResultError[] = [];
        if (error && error.response) {
            if (error.response.status === 401) {
                this.logout();
            }
            if (error.response.status === 404) {
                console.log("Endpoint not found");
                errors.push({
                    message: "Api endpoint not found",
                    type: ApiResultErrorType.Basic,
                });
            }
        }
        this.setIsLoading(false);
        return { wasSuccessful: false, errors: errors };
    };

    setLoginState = async (apiResult: AccountStatus) => {
        await setJWT(apiResult.jwt);
        //set(".auth", apiResult.jwt as string);
        //EN: Hack at the moment so that we do not get cicular references
        // let stores = (window as any).Stores as Stores;
        // if (stores) {
        // 	stores.domain.AccountStore.getLoginState(apiResult);
        // }
    };

    logout = () => {
        //(window as any).Stores.domain.AccountStore.Logout();
        this.history.push("/logout");
    };

    getConfig = async (useBearer: boolean, config?: Axios.AxiosRequestConfig) => {
        const requestConfig = MergeDefaultConfig(config);
        //Sets the bearer on every header if available
        //Note: You might need to remove this bearer if calling 3rd party api's
        let jwt = await getJWT();
        if (jwt && jwt.length > 0 && useBearer) {
            requestConfig.headers = {
                Authorization: "Bearer " + jwt,
                "content-type": "application/json",
            };
        } else {
            requestConfig.headers = {
                "content-type": "application/json",
            };
        }
        return requestConfig;
    };
}
