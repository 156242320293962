import { Box, Checkbox, FormControl, FormControlLabel, FormHelperText, InputAdornment, InputLabel, ListItemText, MenuItem, Radio, TextField } from "@material-ui/core";
import { generateID, isNullOrEmpty } from "../../Utils/Utils";
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { getPath } from "ts-object-path";
import React, { PropsWithChildren, useEffect, useRef } from "react";

import FormLabel from "@material-ui/core/FormLabel";
import { IModel, KeyValuePair, FieldType } from "../../Models";
import { IViewModel } from "../../ViewModels";
import MomentUtils from "@date-io/moment";
import RadioGroup from "@material-ui/core/RadioGroup";
import Select from "@material-ui/core/Select";
import { MULTISELECT, Validation } from "./Validation";
import styled from "styled-components";
import { useObserver } from "mobx-react-lite";
import { AddImage } from "../AddImage";
import DoneIcon from "@material-ui/icons/Done";
import { BoxProps } from "@material-ui/core/Box/Box";

const Control = styled(FormControl)`
    min-width: 120px;
`;

type TModel<T> = IModel<T> & any;

type InputProps = {
    [x: string]: any;
};

type inputProps = {
    [x: string]: any;
};

type InputLabelProps = {
    [x: string]: any;
};

export type EditableInputProps<T> = {
    /**
     * Set this property to aid in finding elements in Cypress
     */
    "data-cy"?: string;
    /**
     * Tell the browser not to auto fill this field
     */
    noFill?: boolean;
    viewModel: IViewModel<T>;
    onChangeCallback?: any;
    fieldName: keyof FieldType<T>;
    /**
     * Key value pair to display in a select element or radio group
     * Value is displayed in the select dropdown to the user
     * Key usually gets stored in the DB. It is the underlying value of the select box
     */
    selectItems?: KeyValuePair[];
    InputProps?: InputProps;
    inputProps?: inputProps;
    InputLabelProps?: InputLabelProps;
    /**
     * The maximum character count allowed
     */
    maxLength?: number;
    /**
     * This field is set to be required by the validation
     */
    required?: boolean;
    /**
     * Whether the field is editable or not
     */
    editMode?: boolean;
    /**
     * Set to true if you want the label to always appear outside of the box
     */
    shrink?: boolean;
    validateOnStart?: boolean;
    validateOnBlur?: boolean;
    /**
     * Make the field expand the entire width of its parent
     */
    fullwidth?: boolean;
    type?: "text" | "number" | "tel" | "password" | "email" | "checkbox" | "radio" | "select" | "date" | "time" | "datetime" | "multiselect" | "file" | "postcode";
    label?: string | React.ReactElement;
    /**
     * Size of the label text
     */
    labelSize?: string;
    /**
     * Default value for this field
     */
    defaultValue?: string;
    /**
     * Label that gets displayed underneath the field
     */
    helperText?: string;
    color?: "initial" | "inherit" | "primary" | "secondary" | "textPrimary" | "textSecondary" | "error";
    /**
     * Set to true if you want the text box to become a text area. Also set rows prop to a value greater than 1
     */
    multiLine?: boolean;
    /**
     * Set how many rows you want to show in the text area
     */
    component?: React.ReactElement;
    placeHolder?: string;
    rows?: number;
    variant?: "filled" | "outlined" | "standard";
    //[x: string]: IViewModel | undefined | string | boolean | InputProps | KeyValuePair[];
} & PropsWithChildren<BoxProps>;

export function EditableInput<T>(props: EditableInputProps<T>) {
    const rendered = useRef<boolean>(false);
    let datacy: any = "none";
    const {
        viewModel,
        multiLine,
        selectItems,
        inputProps,
        InputProps,
        labelSize,
        fullwidth,
        type,
        label,
        variant,
        maxLength,
        placeHolder,
        validateOnStart,
        defaultValue,
        rows,
        validateOnBlur,
        onChangeCallback,
        helperText,
        editMode,
        noFill,
        shrink,
        fieldName,
        ...boxProps
    } = props;

    const [bind, { text, password, radio, checkbox, date, time, email, select, number, multiselect, tel, file, postcode }] = Validation<T>(viewModel, onChangeCallback);
    const fileRef = useRef(null);

    let localFieldName: keyof FieldType<T> = fieldName as any;
    if (typeof localFieldName !== "string") {
        const p = getPath(localFieldName as any as string);
        localFieldName = p.join(".") as any;
    }

    datacy = props["data-cy"] ? props["data-cy"] : localFieldName;

    useEffect(() => {
        rendered.current = true;
        if (props.validateOnStart) {
            validate(viewModel.getValue(localFieldName));
        }
    }, []);

    const validate = async (value: any) => {
        await viewModel.isFieldValid(localFieldName, value);
    };

    const isInError = (): boolean => {
        let isValid = viewModel.getValid(localFieldName);
        if (props.validateOnStart) {
            return !isValid;
        }

        if (!rendered.current) {
            return false;
        }
        return !isValid;
    };

    const renderControl = () => {
        if (type === "checkbox") {
            return checkBox();
        } else if (type === "radio") {
            return radiocomp();
        } else if (type === "select" || type === "multiselect") {
            return selectField();
        } else if (type === "date") {
            return dateComp();
        } else if (type === "time") {
            return timeComp();
        } else if (type === "file") {
            return fileInputField();
        } else {
            return textField();
        }
    };

    const dateComp = () => {
        return (
            <>
                <Box {...boxProps}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                            color={"secondary"}
                            data-cy={datacy}
                            className={props.className}
                            placeholder="01/JAN/2020"
                            label={props.label}
                            {...date(localFieldName, {
                                validateOnBlur: props.validateOnBlur,
                            })}
                            mask={"__-__-____"}
                            format="DD-MMM-yyyy"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </MuiPickersUtilsProvider>
                    {isInError() && <FormHelperText style={{ color: "red" }}>{viewModel.getError(localFieldName)}</FormHelperText>}
                </Box>
            </>
        );
    };

    const timeComp = () => {
        return (
            <>
                <Box {...boxProps}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardTimePicker
                            data-cy={datacy}
                            className={props.className}
                            placeholder="08:00 AM"
                            label={props.label}
                            mask={"__:__ _M"}
                            {...time(localFieldName, {
                                validateOnBlur: props.validateOnBlur,
                            })}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            KeyboardButtonProps={{
                                "aria-label": "change date",
                            }}
                        />
                    </MuiPickersUtilsProvider>
                    {isInError() && <FormHelperText style={{ color: "red" }}>{viewModel.getError(localFieldName)}</FormHelperText>}
                </Box>
            </>
        );
    };

    const radiocomp = () => {
        return (
            <Box {...boxProps}>
                <Control className={props.className} error={isInError()}>
                    <FormLabel component="legend" id={props.label + "-radio-label"}>
                        {props.label}
                    </FormLabel>
                    <RadioGroup
                        {...radio(localFieldName, { validateOnBlur: props.validateOnBlur })}
                        data-cy={datacy}
                        row={true}
                        aria-label={props.label as string}
                        name={props.label as string}
                    >
                        {props.selectItems!.map((item: KeyValuePair, index: number) => {
                            return (
                                <FormControlLabel
                                    data-cy={`${datacy}-${index}`}
                                    key={generateID()}
                                    value={item.value}
                                    aria-label={props.label as string}
                                    name={props.label as string}
                                    control={<Radio color={"secondary"} />}
                                    label={item.key}
                                />
                            );
                        })}
                    </RadioGroup>
                    {isInError() && <FormHelperText style={{ color: "red" }}>{viewModel.getError(localFieldName)}</FormHelperText>}
                </Control>
            </Box>
        );
    };

    const multiSelectProps = {
        get renderValue() {
            return (selected: any) => {
                if (selected.length > 1) {
                    return "Multiple items selected";
                }
                return selected[0];
                /*if (props.type === MULTISELECT) {
                    props.viewModel.setValue(fieldName, selected.join(", "));
                    //return selected.join(", ");
                    return "Multiple items selected";
                }
                return selected;*/
            };
        },
    };

    const selectField = () => {
        return (
            <Box {...boxProps}>
                <Control style={{ minWidth: "100%" }} className={props.className} error={isInError()}>
                    <InputLabel id="select-label">{props.label}</InputLabel>
                    <Select
                        data-cy={datacy}
                        placeholder={props.placeHolder}
                        inputProps={{
                            ...props.inputProps,
                            maxLength: props.maxLength,
                            className: props.className,
                            style: props.style,
                        }}
                        MenuProps={{
                            disableScrollLock: true,
                        }}
                        {...(type === MULTISELECT && multiSelectProps)}
                        {...(type === MULTISELECT
                            ? multiselect(props.fieldName as any, {
                                  validateOnBlur: props.validateOnBlur,
                              })
                            : select(localFieldName, {
                                  validateOnBlur: validateOnBlur,
                                  selectItems: selectItems,
                              }))}
                        {...(inputProps || {})}
                    >
                        {selectItems!.map((item: KeyValuePair) => {
                            return type === "select" ? (
                                <MenuItem data-cy={`${datacy}-${item.value}`} key={label + "-" + generateID()} value={item.value}>
                                    {item.key}
                                </MenuItem>
                            ) : (
                                <MenuItem key={label + "-" + generateID()} value={item.value}>
                                    <Checkbox checked={viewModel.getValue<string[]>(props.fieldName as any).filter((a: any) => a == item.value).length > 0} />
                                    <ListItemText primary={item.key} />
                                </MenuItem>
                            );
                        })}
                    </Select>
                    {isInError() && <FormHelperText style={{ color: "red" }}>{viewModel.getError(localFieldName)}</FormHelperText>}
                </Control>
            </Box>
        );
    };

    const fileInputField = () => {
        return (
            <Box {...boxProps}>
                <Box>
                    {viewModel.getValue(localFieldName) === null || viewModel.getValue(localFieldName) === "" ? (
                        <Box style={{ cursor: "pointer" }} onClick={() => (fileRef!.current! as any).click()}>
                            {props.component === null ? <AddImage /> : props.component}
                        </Box>
                    ) : (
                        <img width={"160px"} src={viewModel.getValue(localFieldName) ?? ""} alt={""} />
                    )}
                    <input style={{ display: "none" }} ref={fileRef} type="file" {...file(localFieldName, { validateOnBlur: props.validateOnBlur })} />
                </Box>
            </Box>
        );
    };

    const getTypeOfInput = () => {
        if (type === "number") {
            return number(localFieldName, { validateOnBlur: validateOnBlur });
        } else if (type === "tel") {
            return tel(localFieldName, { validateOnBlur: validateOnBlur });
        } else if (type === "password") {
            return password(localFieldName, { validateOnBlur: validateOnBlur });
        } else if (type === "email") {
            return email(localFieldName, { validateOnBlur: validateOnBlur });
        } else if (type === "postcode") {
            return postcode(localFieldName, { validateOnBlur: validateOnBlur });
        }
        return text(localFieldName, { validateOnBlur: validateOnBlur });
    };

    const getStartAdornment = () => {
        let retval = <></>;
        if (type === "postcode") {
            if ((viewModel as any).getModel["location"] !== undefined) {
                retval = (
                    <InputAdornment position="end">
                        <DoneIcon style={{ color: "green" }} />
                    </InputAdornment>
                );
            } else {
                /*retval = (
                    <InputAdornment position="start">
                        <ClearIcon style={{ color: "red" }} />
                    </InputAdornment>
                );*/
            }
        }
        return retval;
    };

    const textField = () => {
        if (editMode) {
            return (
                <Box {...boxProps}>
                    <TextField
                        className={props.className}
                        aria-label={label as string}
                        fullWidth
                        {...getTypeOfInput()}
                        InputLabelProps={{
                            shrink: shrink ?? undefined,
                            style: { color: props.color, fontSize: props.labelSize },
                            ...props.InputLabelProps,
                        }}
                        InputProps={{ ...InputProps, endAdornment: getStartAdornment() }}
                        inputProps={{
                            ...inputProps,
                            maxLength: maxLength,
                            className: props.className,
                            style: props.style,
                            "data-cy": datacy,
                        }}
                        label={label}
                        placeholder={placeHolder}
                        defaultValue={defaultValue}
                        multiline={multiLine}
                        minRows={rows}
                        autoComplete={noFill ? "new-password" : type}
                        variant={variant}
                        helperText={isNullOrEmpty(viewModel.getError(localFieldName)) ? helperText : viewModel.getError(localFieldName)}
                    />
                </Box>
            );
        } else {
            return (
                <InputLabel className={"input-label"} id={"text-label-" + generateID()}>
                    {viewModel.getValue(localFieldName)}
                </InputLabel>
            );
        }
    };

    const checkBox = () => {
        return (
            <Box {...boxProps}>
                <FormControlLabel
                    data-cy={datacy}
                    style={{ color: isInError() ? "red" : "black" }}
                    control={
                        <Checkbox
                            className={props.className}
                            color={"secondary"}
                            {...checkbox(localFieldName, {
                                validateOnBlur: props.validateOnBlur,
                            })}
                        />
                    }
                    label={props.label}
                />
                {isInError() && (
                    <FormHelperText style={{ color: "red" }}>
                        {/*{errorMessage}*/}
                        {viewModel.getError(localFieldName)}
                    </FormHelperText>
                )}
            </Box>
        );
    };

    const formatSelectValue = (value: any) => {
        let retval = props.selectItems!.find((a) => a.value == value);
        if (retval) return retval!.key;
        else return "";
    };

    return useObserver(() => <Box>{renderControl()}</Box>);
}

EditableInput.defaultProps = {
    className: "",
    noFill: false,
    //shrink: false,
    color: "rgba(0, 0, 0, 0.7)",
    style: {},
    component: null,
    type: "text",
    editMode: true,
    validateOnStart: false,
    validateOnBlur: true,
    fullwidth: true,
    helperText: "",
    labelSize: "1rem",
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
    rows: 5,
    variant: "standard",
    inputProps: {
        maxLength: undefined,
    },
};
