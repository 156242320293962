import { observable, action } from "mobx";
import { set as _set } from "lodash";
import { get as _get } from "lodash";
import { IModel } from "./IModel";
import { InputValuesDirty, InputValuesTouched, InputValuesErrors, InputValuesValidity } from "./IModel";
import { getParentObjectPath } from "../Utils/Utils";
import { FieldType } from "../Models";

export abstract class ModelBase<T = any, TDTO = any> implements IModel<T> {
    @observable public Errors = {} as InputValuesErrors<T>;
    @observable public Valid = {} as InputValuesValidity<T>;
    @observable public Dirty = {} as InputValuesDirty<T>;
    @observable public Touched = {} as InputValuesTouched<T>;

    abstract fromDto(model: TDTO): void;
    abstract toDto(model: T): void;

    constructor() {
        //Loop through added properties setting their default values
        for (let prop in this) {
            if (prop != "Errors" && prop != "Valid" && prop != "Dirty" && prop != "Touched") {
                // @ts-ignore
                this.Errors[prop] = "";
                // @ts-ignore
                this.Valid[prop] = true;
                // @ts-ignore
                this.Dirty[prop] = false;
                // @ts-ignore
                this.Touched[prop] = false;
            }
        }
    }

    private safeSetValue(fieldName: any, value: any) {
        if (window.IE11) {
            (this as any)[fieldName] = value;
        } else {
            _set(this, fieldName as any, value);
        }
    }
    @action
    public setValue<TR>(fieldName: keyof FieldType<T>, value: TR): void {
        this.safeSetValue(fieldName as any, value);
    }

    public getValue<TR>(fieldName: keyof FieldType<T>): TR {
        return _get(this, fieldName as any);
    }

    @action
    public setError(fieldName: keyof FieldType<T>, value: string): void {
        let path = getParentObjectPath(fieldName as any, "Errors");
        this.safeSetValue(path, value);
    }

    public getError(fieldName: keyof FieldType<T>): string {
        let path = getParentObjectPath(fieldName as any, "Errors");
        return _get(this, path);
    }

    @action
    public setValid(fieldName: keyof FieldType<T>, value: boolean): void {
        let path = getParentObjectPath(fieldName as any, "Valid");
        this.safeSetValue(path, value);
    }

    public getValid(fieldName: keyof FieldType<T>): boolean {
        let path = getParentObjectPath(fieldName as any, "Valid");
        return _get(this, path);
    }

    @action
    public setDirty(fieldName: keyof FieldType<T>, value: boolean): void {
        let path = getParentObjectPath(fieldName as any, "Dirty");
        this.safeSetValue(path, value);
    }

    public getDirty(fieldName: keyof FieldType<T>): boolean {
        let path = getParentObjectPath(fieldName as any, "Dirty");
        return _get(this, path);
    }

    @action
    public setTouched(fieldName: keyof FieldType<T>, value: boolean): void {
        let path = getParentObjectPath(fieldName as any, "Touched");
        this.safeSetValue(path, value);
    }

    public getTouched(fieldName: keyof FieldType<T>): boolean {
        let path = getParentObjectPath(fieldName as any, "Touched");
        return _get(this, path);
    }
}
