import React from "react";
import { useSpring, animated } from "react-spring";
import styled from "styled-components";

const StyledButton = styled.button`
    min-width: 200px;
    padding: 24px;
    border-radius: 9999px;
    border: none;
    background-color: #f12742;
    color: white;
    font-size: 16px;
    font-weight: 500;
    outline: none;
`;

const AnimatedButton = animated(StyledButton);

function remap01(a: number, b: number, t: number) {
    return (t - a) / (b - a);
}

function remap(a: number, b: number, c: number, d: number, t: number) {
    return remap01(a, b, t) * (d - c) + c;
}

const calc = (clientX: number, clientY: number, rect: any) => {
    const hw = rect.width / 2;
    const hh = rect.height / 2;
    const x = -(clientY - rect.top - hh);
    const y = clientX - rect.left - hw;
    const s = 1.1;
    return [remap(-hh, hh, -25, 25, x), remap(-hw, hw, -15, 15, y), s];
};
const trans = (x: number, y: number, s: number) => `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;

const shadow = (x: number, y: number, s: number) => {
    const sx = remap(-15, 15, 3, -3, y);
    const sy = remap(-25, 25, -5, 5, x);
    return `${sx}px ${sy}px 0 #C00C24`;
};

const background = (x: number, y: number, s: number) => {
    const bl = remap(-15, 15, 45, 60, y);
    const br = remap(-15, 15, 60, 45, y);
    return `linear-gradient(to right, hsl(352, 88%, ${bl}%), hsl(352, 88%, 55%), hsl(352, 95%, ${br}%))`;
};

export const ParalaxButton = (props: any) => {
    const ref = React.useRef(null);
    const [styles, set] = useSpring(() => ({
        xys: [0, 0, 1],
        config: { mass: 1, tension: 180, friction: 16 },
    }));

    return (
        <AnimatedButton
            {...props}
            ref={ref}
            onMouseMove={(event: any) => {
                const rect = (ref.current as any).getBoundingClientRect();
                set({ xys: calc(event.clientX, event.clientY, rect) });
            }}
            onMouseLeave={() => set({ xys: [0, 0, 1] })}
            style={{
                transform: styles.xys.interpolate(trans as any),
                boxShadow: styles.xys.interpolate(shadow as any),
                backgroundImage: styles.xys.interpolate(background as any),
                // boxShadow: styles.xys.interpolate(
                //   (x, y, s) => `0 2px 8px rgba(0,0,0, ${(s - 1) * 3})`
                // )
            }}
        />
    );
};
