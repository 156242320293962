import { Box, Button, Fade, Paper, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { EditableInput } from "../Components";
import { KeyValuePair } from "../Models";
import ReactJson from "react-json-view";
import { TestModel } from "../Models";
import { TestViewModel } from "../ViewModels";
import { Grid, Validation } from "../Components";
import { autorun } from "mobx";
import styled from "styled-components";

export const TestStyle = styled(EditableInput)<any>``;
const MainGrid = styled.section`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 2fr;
`;

export const TestPage: React.FunctionComponent = () => {
    const viewModel = TestViewModel.Instance;
    //const [viewModel] = useState(() => new TestViewModel());

    const context = viewModel.getContext();
    const [bind, { text, password }] = Validation<TestModel>(viewModel);
    const [json, setJson] = useState("{}");

    //useEffect below only gets run on initial render
    useEffect(() => {
        // *optional* passing the router into the viewmodel so we can navigate from there
        autorun(() => {
            //let a = viewModel.getModel.firstName;
            let p = JSON.stringify(viewModel.getModel, null, 2);
            setJson(p);
        });

        //This gets run when the page is exited
        return () => {
            console.log("Unmounting");
        };
    }, []);

    console.log("Rendered");

    return (
        <Fade in timeout={1000}>
            <Paper>
                <form onSubmit={viewModel.doSubmit}>
                    {viewModel.errorMessage !== "" && (
                        <Typography variant="h2" style={{ color: "red" }}>
                            {viewModel.errorMessage}
                        </Typography>
                    )}
                    {viewModel.validMessage !== "" && (
                        <Typography variant="h2" style={{ color: "green" }}>
                            {viewModel.validMessage}
                        </Typography>
                    )}
                    <MainGrid>
                        <Grid dc={"1fr"} rowGap={"10px"} paddingTop={2} paddingLeft={2}>
                            {/*Here we are using the *optional* generic type of the model to give us intellisense on the fieldname*/}
                            <EditableInput<TestModel>
                                id={"firstNameField"}
                                type="text"
                                label="First Name"
                                maxLength={10}
                                helperText={"Enter your first name"}
                                validateOnBlur={true}
                                viewModel={viewModel}
                                fieldName="firstName"
                                variant={"outlined"}
                                shrink={true} //Keep the helper label above the input all the time
                            />
                            <EditableInput<TestModel>
                                type="text"
                                label="First Name:"
                                helperText={"Enter your first name"}
                                validateOnBlur={true}
                                viewModel={viewModel}
                                fieldName="firstName"
                                shrink={true} //Keep the helper label above the input all the time
                            />
                            <EditableInput<TestModel>
                                type="date"
                                label="DOB:"
                                helperText={"Enter your DOB"}
                                validateOnBlur={true}
                                viewModel={viewModel}
                                fieldName="dob"
                                shrink={true} //Keep the helper label above the input all the time
                            />
                            <TestStyle type="text" label="Last Name" validateOnBlur={true} viewModel={viewModel} fieldName={"lastName"} />
                            <EditableInput
                                type="select"
                                label="Age"
                                viewModel={viewModel}
                                fieldName={"age"}
                                selectItems={
                                    [
                                        { value: 0, key: "Zero" },
                                        { value: 10, key: "Ten" },
                                        { value: 20, key: "Twenty" },
                                        { value: 30, key: "Thirty" },
                                        { value: 40, key: "A lot older than I look" },
                                    ] as KeyValuePair[]
                                }
                            />
                            <EditableInput data-cy={"age2"} shrink={true} type="number" label="Age" viewModel={viewModel} fieldName="age" />
                            <EditableInput<TestModel> type="text" multiLine={true} rows={10} label="Memo" viewModel={viewModel} fieldName="memo" />

                            <TextField fullWidth type="text" label="Username" {...text(bind.userName, { validateOnBlur: true })} helperText={"Enter your username"} />

                            <TextField fullWidth label={"Address Line 1"} {...text(bind.address.addressLine1)} />

                            <input {...text("userName")} />
                        </Grid>
                        {/*<textarea style={{ height: "100%", width: "100%" }} defaultValue={json} />*/}
                        <ReactJson style={{ fontSize: "12px" }} src={JSON.parse(json)} />
                        <Button color="inherit" onClick={viewModel.TestBearer}>
                            Test Call
                        </Button>
                        <Button onClick={viewModel.goToAdmin}>Go To Admin</Button>
                    </MainGrid>
                </form>
            </Paper>
        </Fade>
    );
};
