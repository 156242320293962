/* eslint-disable @typescript-eslint/no-non-null-assertion */
// Libs

// App
import { observable, computed, action } from "mobx";

export class BaseStore {
    @observable protected isLoadingCount = 0;
    @observable public isLoaded = true;
    @computed public get isLoading() {
        return this.isLoadingCount > 0;
    }
    public constructor() {}

    @action protected setIsLoading = () => {
        this.isLoadingCount++;
        this.isLoaded = false;
    };
    @action protected unsetIsLoading = () => {
        this.isLoadingCount--;
        if (this.isLoadingCount === 0) {
            this.isLoaded = true;
        }
    };
}
