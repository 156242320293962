import React from "react";

type Props = {
    width?: string | number;
};
export const AddImage: React.FC<Props> = (props: Props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="169" height="89" viewBox="0 0 169 89">
            <g id="Group_4623" data-name="Group 4623" transform="translate(-60 -301)">
                <g id="Rectangle_1517" data-name="Rectangle 1517" transform="translate(60 301)" fill="#e7ebed" stroke="#707070" strokeWidth="1" strokeDasharray="5">
                    <rect width="169" height="89" stroke="none" />
                    <rect x="0.5" y="0.5" width="168" height="88" fill="none" />
                </g>
                <g id="Group_4622" data-name="Group 4622" transform="translate(27 212)">
                    <text
                        id="Click_to_add_an_image"
                        data-name="Click to add an image"
                        transform="translate(118 155)"
                        fill="#7f7f7f"
                        fontSize="10"
                        fontFamily="Montserrat-Regular, Montserrat"
                        letterSpacing="-0.015em"
                    >
                        <tspan x="-53.01" y="0">
                            Click to add an image
                        </tspan>
                    </text>
                    <g id="Group_4619" data-name="Group 4619" transform="translate(2397.935 19354)">
                        <path
                            id="Path_3237"
                            data-name="Path 3237"
                            d="M37.468,11.6H4.158A1.637,1.637,0,0,0,2.5,13.241V39.26A1.637,1.637,0,0,0,4.158,40.9h33.31a1.637,1.637,0,0,0,1.658-1.641V13.241A1.637,1.637,0,0,0,37.468,11.6ZM35.81,14.881V33.728l-6.824-7.4a1.787,1.787,0,0,0-2.622,0l-2.93,3.167-6.4-7.287a1.96,1.96,0,0,0-2.93,0L5.816,31.591V14.881Z"
                            transform="translate(-2301 -19256.6)"
                            fill="#7f7f7f"
                        />
                        <circle id="Ellipse_140" data-name="Ellipse 140" cx="3.568" cy="3.568" r="3.568" transform="translate(-2274.785 -19239.336)" fill="#7f7f7f" />
                    </g>
                </g>
            </g>
        </svg>
    );
};
AddImage.defaultProps = {
    width: "160px",
};
