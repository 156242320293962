// App

import { HttpError, HttpForbiddenError, HttpUnauthorizedError } from "../Errors";

export const HttpStatusToError: (httpStatusCode: number, httpStatusText: string) => {} = (httpStatusCode, httpStatusText) => {
    if (httpStatusCode === 401) {
        return new HttpUnauthorizedError();
    } else if (httpStatusCode === 403) {
        return new HttpForbiddenError();
    } else {
        return new HttpError(httpStatusCode, httpStatusText);
    }
};
